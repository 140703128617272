import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

type PrivateRouteProps = {
  component: any
  referringPath: string
  location: Location
  path: string
  default?: boolean
  rest?: any
}

const PrivateRoute = ({ component: Component, location, referringPath, ...rest }: PrivateRouteProps) => {
  const {authenticated, signout} = useAuth();
  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('/api/auth/status', {
          method: 'POST',
          credentials: 'same-origin',
        });
        if (!response.ok) {
          if (response.status === 401) {
            console.error('Session invalid or expired. Logging out.');
            if (authenticated){
              await signout();
            }
          } 
        }
      } catch (error) {
        console.error('Error verifying session:', error);
      }
    };

    checkSession();
  }, []);

  if (!authenticated) {
    navigate('/login',{ state: { referrer: referringPath } });
    return null;
  } 
  return <Component {...rest} />
}

export default PrivateRoute;