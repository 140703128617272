import iconGear from '../../images/icon-gear.svg';
import iconChatBubble from '../../images/icon-chat-bubble.svg';
import iconCreditCard from '../../images/icon-credit-card.svg';
import iconSignOut from '../../images/icon-x-circle.svg';
import iconSavedArticles from '../../images/icon-saved-articles.svg';
import iconBell from '../../images/icon-bell.svg';

const wpApiUrl: string = 'https://childrenshealthdefense.org/wp-json/wp/v2/';
const dashboardBaseUrl = '/dashboard';
const SITE_PAGES = {
  DashboardHome: {
    id: 'dashboard-home',
    slug: '',
    title: 'Dashboard',
    text: 'Dashboard',
    description: '',
    url: dashboardBaseUrl,
    icon: iconGear,
    alt: 'Gear icon',
    showInDashboardNavMenu: false,
  },
  MyAccount: {
    id: 'my-account',
    slug: 'my-account',
    title: 'My Account',
    text: 'My Account',
    description: '',
    url: `${dashboardBaseUrl}/my-account`,
    icon: iconGear,
    alt: 'Gear icon',
    showInDashboardNavMenu: true,
  },
  ChdUpdates: {
    id: 'chd-updates',
    slug: 'chd-updates',
    title: 'The Latest',
    text: 'The Latest',
    description: 'Keep up-to-date on the latest happenings at Children’s Health Defense. Click on each update to learn more.',
    url: `${dashboardBaseUrl}/chd-updates`,
    icon: iconBell,
    alt: 'Bell icon',
    showInDashboardNavMenu: true,
  },
  MyDonations: {
    id: 'my-donations',
    slug: 'my-donations',
    title: 'My Donations',
    text: 'My Donations',
    description: 'View or modify your donations in one convenient location.',
    url: `${dashboardBaseUrl}/my-donations`,
    icon: iconCreditCard,
    alt: 'Credit card icon',
    showInDashboardNavMenu: true,
  },
  CommunityForum: {
    id: 'community-forum',
    slug: 'community-forum',
    title: 'Community Forum',
    text: 'Community Forum',
    description: 'Browse topics, ask questions, and find uncensored answers within our online community. This forum allows members to engage in community discussions, share information, discover resources and solve problems with other members of the community, including the staff from Children’s Health Defense - free from censorship or judgment.',
    url: `${dashboardBaseUrl}/community-forum`,
    icon: iconChatBubble,
    alt: 'Chat bubble icon',
    showInDashboardNavMenu: true,
  },
  SavedArticles: {
    id: 'saved-articles',
    slug: 'saved-articles',
    title: 'Saved Articles',
    text: 'Saved Articles',
    description: 'Save your favorite Defender articles. On any Defender article, click the icon in the top right corner. The article will be saved here for later viewing according to when you save them. You can delete from your account anytime by clicking the “-” on each one.',
    url: `${dashboardBaseUrl}/saved-articles`,
    icon: iconSavedArticles,
    alt: 'Saved Articles Icon',
    showInDashboardNavMenu: true,
  },
  Login: {
    id: 'login',
    slug: 'login',
    title: 'Login',
    text: 'Login',
    description: '',
    url: '/login/',
    icon: '',
    alt: '',
    showInDashboardNavMenu: false,
  },
  Signup: {
    id: 'signup',
    slug: 'signup',
    title: 'Sign up',
    text: 'Sign up',
    description: '',
    url: '/signup',
    icon: '',
    alt: '',
    showInDashboardNavMenu: false,
  },
  Signout:{
    id: 'sign-out',
    slug: 'sign-out',
    title: 'Sign Out',
    text: 'Sign Out',
    description: '',
    url: '/sign-out/',
    icon: iconSignOut,
    alt: 'X inside of circle icon',
    showInDashboardNavMenu: false,
  },
  ResetPassword: {
    id: 'reset-password',
    slug: 'reset-password',
    title: 'Reset Password',
    text: 'Forgot Password',
    description: '',
    url: '/reset-password',
    icon: '',
    alt: '',
    showInDashboardNavMenu: false,
  },
}

export {
  dashboardBaseUrl,
  SITE_PAGES,
  wpApiUrl,
};