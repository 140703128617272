import { Sha256 } from '@aws-crypto/sha256-js';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const groupBy = (arr: any, criteria: any) => {
  const newObj = arr.reduce((acc: any, currentValue: any) => {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
};

const supportedLocales = [
  { id: 'en', title: 'English', isDefault: true },
  { id: 'es', title: 'Spanish' },
  { id: 'pt', title: 'Portuguese' },
  { id: 'fr', title: 'French' },
  { id: 'de', title: 'German' },
  { id: 'it', title: 'Italian' },
];

const uint8ArrayToHex = (uint8Array: Uint8Array) => {
  return Array.from(uint8Array)
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
}

const signRequest = async () => {
  try {
    const { data } = await axios.post(
      '/api/sign-request',
      {
        ts: Math.floor(Date.now() / 1000),
        tk: uuidv4()
      });
    
    return {
      success: data?.success,
      data: data?.data
    }
  } catch (err: any) {
    throw err;
  }
}

function createUrlWithParams(url: URL, param: string) {
  const prevUrl = new URL(url);
  prevUrl.search = param;
  const newUrl = prevUrl.toString();
  return newUrl;
}

const QUERY_IDS = {
  BECOME_INSIDER_ID: 'become-insider',
  MILLION_MATCH_QUERY_ID: 'million-match',
};

export {
  createUrlWithParams,
  groupBy,
  QUERY_IDS,
  signRequest,
  supportedLocales, 
  uint8ArrayToHex,
};
