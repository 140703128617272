
import { graphql, useStaticQuery } from 'gatsby';
import {
  createUserWithEmailAndPassword,
  getIdToken,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User,
} from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import React, {
  createContext, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { auth, db, functions, googleProvider, httpsCallable } from '../../.services';

const discourseLogout = httpsCallable(functions, 'discourseLogout');

const defaultContext = {
  currentUser: null,
};
const AuthContext = createContext<any>(defaultContext);

function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }: { children: any }) {
  const query = useStaticQuery(graphql`
    {
      signUpLink: contentfulExternalLink(queryId:{eq: "become-insider"}) {
        url
      }
    }
  `);
  const {signUpLink: { url: signUpUrl} } = query;
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentUserClaims, setCurrentUserClaims] = useState<any | null>();
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const [unsubListenerClaims, setUnsubListenerClaims] = useState<any | null>(null);
  const initialAuthDone = useRef(false);
  const refreshTime = useRef(null);

  function signup(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function login(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await getIdToken(userCredential.user);
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify({ idToken }),
      });
      if (response.ok) {
        setAuthenticated(true);
      } else {
        console.error('Session login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
      throw(error);
    }
  }

  function loginWithGoogle() {
    return signInWithPopup(auth, googleProvider);
  }

  function signout() {
    return signOut(auth).then(() => {
      setAuthenticated(false)
      if (unsubListenerClaims) {
        unsubListenerClaims();
        setUnsubListenerClaims(null);
      }
      initialAuthDone.current = false;
      refreshTime.current = null;
      setCurrentUserClaims(null);
      setCurrentUser(null);
    });
  }

  async function logout() {
    try {
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'same-origin',
      });
  
      if (!response.ok) {
        console.error('Failed to log out:', response.statusText);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }

    try {
      const response2 = await discourseLogout({uid: currentUser?.uid});
      console.log('discourseLogout:', response2);
    } catch (error) {
      console.error('Error during Discourse logout: ', error);
    }

    return signout()
  }

  function passwordReset(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateDisplayName(displayName: string) {
    if (!currentUser) return;
    return updateProfile(currentUser, {
      displayName: displayName
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      if (user) {
        setCurrentUser(user);
        setAuthenticated(true);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  // Get Current User's Custom Claims
  useEffect(() => {
    if (!currentUser) { 
      return;
    }

    // Get user's Custom Claims
    currentUser.getIdTokenResult().then((idTokenResult: any) => {
      setCurrentUserClaims(idTokenResult?.claims || null);
    }).catch((error: any) => {
      console.error(error);
    });

    // Listen to Firestore for Custom Claims updates
    const unsubscribe = onSnapshot(
      doc(db, 'members', currentUser.uid),
      (docSnap: any) => {
        const data = docSnap.data();

        if (
          initialAuthDone.current 
          && data?.refreshTime 
          && data.refreshTime !== refreshTime.current
        ) {
          currentUser.getIdTokenResult(true).then((idTokenResult: any) => {
            setCurrentUserClaims(idTokenResult?.claims || null);
          }).catch((error: any) => {
            console.error(error);
          });

          refreshTime.current = data.refreshTime;
        }

        if (!initialAuthDone.current) {
          initialAuthDone.current = true;
        }
      }
    );

    setUnsubListenerClaims(() => unsubscribe);

    return () => {
      unsubscribe();
    }
  }, [currentUser]);

  const value = 
  useMemo(
    () => (
      {
      authenticated,
      currentUser,
      currentUserClaims,
      login,
      loginWithGoogle,
      logout,
      passwordReset,
      signout,
      signup,
      signUpUrl,
      updateDisplayName,
    }
  ),
    [currentUser, currentUserClaims],
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export {
  AuthProvider, useAuth,
};
